import { sortDefined } from './sort-defined.function';
import { SortDirection } from './sort-direction.type';

export function sortBoolean(
  a: boolean | undefined,
  b: boolean | undefined,
  direction: SortDirection = 'asc',
): number {
  const sortDefinedResult: number = sortDefined(a, b, direction);
  if (sortDefinedResult !== 0) {
    return sortDefinedResult;
  }

  if (a === b) {
    return 0;
  }

  return (a ? 1 : 0) * (direction === 'asc' ? 1 : -1);
}
